<template>
  <div v-loading="ifload" class="finance-information-box">
    <div class="parameTitle">
      <span class="leftLine">&nbsp;</span>
      <span class="rightText">融资信息</span>
    </div>
    <ul class="basic-information clear">
      <li class="flex-box">
        <span class="li-label">融资申请编号：</span><span class="flex1">{{ detailData&&detailData.financeInfo.financeNo }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">融资企业名称：</span><span class="flex1">{{ detailData&&detailData.financeInfo.cmpName }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">核心企业名称：</span><span class="flex1">{{ detailData&&detailData.financeInfo.coreCmpName }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">融资币种：</span><span class="flex1">{{ detailData&&detailData.financeInfo.currType }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">融资金额（元）：</span><span class="flex1">{{ detailData&&detailData.financeInfo.appAmt|formatMoney }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">融资申请日期：</span><span class="flex1">{{ detailData&&detailData.financeInfo.createTime|date }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">融资到期日：</span><span class="flex1">{{ detailData&&detailData.financeInfo.appDate|date }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">融资期限：</span><span class="flex1">{{ detailData&&detailData.financeInfo.financeDays }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">贷款用途：</span><span class="flex1">{{ detailData&&$code('loanUse',detailData.financeInfo.loanUse) }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">收款账户：</span><span class="flex1">{{ detailData&&detailData.financeInfo.recvAccountNo }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">融资状态：</span><span class="flex1">{{ detailData&&$codeNew(loanState,detailData.financeInfo.appStat) }}</span>
      </li>
      <li v-if="detailData.prodInfo && detailData.prodInfo['bankVersion']=='ABC_V2.0'" class="flex-box" style="width: 100%">
        <span class="li-label">个人征信：</span>
        <span v-if="detailData.personalCredit&& detailData.personalCredit['fileName']" class="flex1 down-file" @click="downFileByIdBefore(detailData.personalCredit)">{{ detailData&&detailData.personalCredit&& detailData.personalCredit['fileName'] }}</span>
        <span v-else class="flex1 down-file">无</span>
      </li>
    </ul>

    <div class="parameTitle">
      <span class="leftLine">&nbsp;</span>
      <span class="rightText">产品信息</span>
    </div>
    <ul v-if="detailData.prodInfo" class="basic-information clear">
      <li class="flex-box">
        <span class="li-label">产品编码：</span><span class="flex1">{{ detailData&&detailData.prodInfo.proId }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">资方产品编号：</span><span class="flex1">{{ detailData&&detailData.prodInfo.proFundNo }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">资金方业务主体编号：</span><span class="flex1">{{ detailData&&detailData.prodInfo.proFundMainNo }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">资金方：</span><span class="flex1">{{ detailData&&detailData.prodInfo.proFundName }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">产品名称：</span><span class="flex1">{{ detailData&&detailData.prodInfo.proName }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">产品类别：</span><span class="flex1">{{ detailData&&$code('proCate',detailData.prodInfo.proCate) }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">产品品种：</span><span class="flex1">{{ detailData&&$childrenCode('proCate',detailData.prodInfo.proCate,detailData.prodInfo.proType) }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">最大可融资比例：</span><span class="flex1">{{ detailData?detailData.prodInfo.creditRatio+'%':'' }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">明暗标识：</span><span class="flex1">{{ detailData&&(parseInt(detailData.prodInfo.proMaflag)===0?'暗保理':'明保理') }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">有无追索权：</span><span class="flex1">{{ detailData&&(parseInt(detailData.prodInfo.proZsflag)===1?'有':'无') }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">融资单据：</span><span class="flex1">{{ detailData&&$code('billType',detailData.prodInfo.proBillType) }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">是否允许提前还款：</span><span class="flex1">{{ detailData&&parseInt(detailData.prodInfo.proPrepaymentFlag)===1?'允许':'不允许' }}</span>
      </li>
      <li class="flex-box">
        <span class="li-label">授信来源：</span><span class="flex1">{{ detailData&&(detailData.prodInfo.proFundRes==='01'?'平台授信':'机构授信') }}</span>
      </li>
    </ul>

    <template v-if="('11,12'.indexOf(detailData.financeInfo.appStat)!=-1 || '11,12'.indexOf(detailData.financeInfo.cancelStat)!=-1) && detailData.prodInfo['bankType']=='AHRCU' ">
      <div class="parameTitle">
        <span class="leftLine">&nbsp;</span>
        <span class="rightText">放款信息</span>
      </div>
      <ul class="basic-information clear">
        <li class="flex-box">
          <span class="li-label">放款金额(本金)：</span><span class="flex1">{{ detailData&&detailData.financeInfo.loanAmt|formatMoney }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">收款账户：</span><span
            class="flex1"
          >{{ detailData&&detailData.financeInfo.recvAccountNo }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">放款日期：</span><span class="flex1">{{ detailData&&detailData.financeInfo.loanDate|date }}</span>
        </li>
      </ul>

      <div class="parameTitle">
        <span class="leftLine">&nbsp;</span>
        <span class="rightText">还款信息</span>
      </div>
      <el-table
        :border="true"
        :data="detailData?detailData.repayInfoList:[]"
        class="br-table"
        style="margin: 30px 0;"
      >
        <el-table-column label="本次还款金额" prop="repayAmt">
          <template slot-scope="scope">
            {{ scope.row.repayAmt|formatMoney }}
          </template>
        </el-table-column>
        <el-table-column label="剩余还款金额" prop="leftRepayAmt">
          <template slot-scope="scope">
            {{ scope.row.leftRepayAmt|formatMoney }}
          </template>
        </el-table-column>
        <el-table-column label="还款日期" prop="repayDate">
          <template slot-scope="scope">
            {{ scope.row.repayDate|date }}
          </template>
        </el-table-column>
      </el-table>
    </template>

    <div class="parameTitle">
      <span class="leftLine">&nbsp;</span>
      <span class="rightText">融资费用</span>
    </div>
    <el-table
      :border="true"
      :data="detailData?detailData.finaFeeInfos:[]"
      class="br-table"
      style="margin: 30px 0;"
    >
      <el-table-column label="费用名称" prop="costName" />
      <el-table-column label="费用标准" prop="costStandard">
        <template
          slot-scope="scope"
        >
          {{ scope.row.costStandard }}{{ costUnitMap[scope.row.costUnit] }}
        </template>
      </el-table-column>
      <el-table-column label="费用金额" prop="costNum">
        <template slot-scope="scope">
          {{ scope.row.costNum|formatMoney }}
        </template>
      </el-table-column>
    </el-table>
    <div class="parameTitle">
      <span class="leftLine">&nbsp;</span>
      <span class="rightText">其他费用</span>
    </div>
    <el-table
      :border="true"
      :data="detailData?detailData.otherFeeInfos:[]"
      class="br-table"
      style="margin: 30px 0;"
    >
      <el-table-column label="费用名称" prop="costName" />
      <el-table-column label="费用标准" prop="costStandard">
        <template
          slot-scope="scope"
        >
          {{ scope.row.costStandard }}{{ costUnitMap[scope.row.costUnit] }}
        </template>
      </el-table-column>
      <el-table-column label="费用金额" prop="costNum">
        <template slot-scope="scope">
          {{ scope.row.costNum|formatMoney }}
        </template>
      </el-table-column>
    </el-table>

    <div class="parameTitle">
      <span class="leftLine">&nbsp;</span>
      <span class="rightText">应收账款信息</span>
      <span class="more" @click="showHide">
        {{ showBillInfo?'收起':'展开' }}
        <i v-if="showBillInfo" class="br-iconfont iconfont icon-arrow-up" />
        <i v-else class="br-iconfont iconfont icon-arrow-prev" />
      </span>
    </div>
    <template v-if="showBillInfo">
      <ul class="basic-information clear">
        <li class="flex-box">
          <span class="li-label">应收账款编号：</span><span class="flex1">{{ detailData&&detailData.financeInfo.billNo }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">应收账款金额：</span><span class="flex1">{{ detailData&&detailData.financeInfo.billAmt|formatMoney }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">应收账款余额：</span><span class="flex1">{{ detailData&&detailData.financeInfo.payableAmt|formatMoney }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">应收账款到期日：</span><span class="flex1">{{ detailData&&detailData.financeInfo.acctDate|date }}</span>
        </li>
      </ul>
      <div class="sub-module">
        <div class="parameTitle sub-title">
          <span class="leftLine">&nbsp;</span>
          <span class="rightText">合同信息</span>
        </div>
        <el-table
          :border="true"
          :data="detailData?detailData.billInfoResponse.contractParams:[]"
          class="br-table"
          style="width: 90%"
        >
          <el-table-column label="序号" type="index" prop="costName" />
          <el-table-column label="合同编码" prop="contractNo" />
          <el-table-column label="合同签订日期">
            <template slot-scope="scope">
              {{ scope.row.contractDate|date }}
            </template>
          </el-table-column>
          <el-table-column label="合同金额" prop="contractAmt">
            <template slot-scope="scope">
              {{ scope.row.contractAmt|formatMoney }}
            </template>
          </el-table-column>
          <el-table-column label="合同附件">
            <template slot-scope="scope">
              <div
                class="down-file"
                @click="downFileById(scope.row.contractAttachs[0])"
              >
                {{ scope.row.contractAttachs[0].oriFileName }}
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div class="parameTitle sub-title">
          <span class="leftLine">&nbsp;</span>
          <span class="rightText">发票信息</span>
        </div>
        <el-table
          :border="true"
          :data="detailData?detailData.billInfoResponse.invoiceParams:[]"
          class="br-table"
          style="width: 90%"
        >
          <el-table-column label="序号" type="index" prop="costName" />
          <el-table-column label="发票代码" prop="invoiceCode" />
          <el-table-column label="发票号码" prop="invoiceNo" />
          <el-table-column label="校验码" prop="checkCode" />
          <el-table-column label="发票金额" prop="invoiceAmt">
            <template slot-scope="scope">
              {{ scope.row.invoiceAmt|formatMoney }}
            </template>
          </el-table-column>
          <el-table-column label="不含税金额" prop="unincludeTaxAmt" min-width="90">
            <template slot-scope="scope">
              {{ scope.row.unincludeTaxAmt|formatMoney }}
            </template>
          </el-table-column>
          <el-table-column label="开票日期" prop="invoiceDate">
            <template slot-scope="scope">
              {{ scope.row.invoiceDate|date }}
            </template>
          </el-table-column>
          <el-table-column label="合同编码" prop="contractNo" />
          <el-table-column label="发票">
            <template slot-scope="scope">
              <div
                v-if="scope.row.billImages.length>0"
                class="down-file"
                @click="downFileById(scope.row.billImages[0])"
              >
                {{ scope.row.billImages[0].oriFileName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="销售方名称" prop="sellCmpName" min-width="140">
            <template slot-scope="scope">
              {{ scope.row.sellCmpName }}
            </template>
          </el-table-column>

          <el-table-column label="采购方名称" prop="buyCmpName" min-width="140">
            <template slot-scope="scope">
              {{ scope.row.buyCmpName }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    detailData: {
      type: Object,
      default: () => ({})
    },
    tabVal: {
      type: String,
      default: () => ('')
    }
  },
  data() {
    return {
      ifload: false,
      costCapitalSideList: [],
      showBillInfo: false,
      // 费用收取方式 00：%、01：笔、02：年化率
      costUnitMap: {
        '00': '%',
        '01': '元/笔',
        '02': '%年化率'
      }
    }
  },
  computed: {
    loanState() {
      return this.$store.getters.getDictionaryItem('LOAN_STATE')
    }
  },
  watch: {
    detailData: {
      handler(val) {
        this.costCapitalSideList = [
          {
            costName: '逾期费',
            collectTime: '逾期时收取',
            nameDefinition: '未按期还款的处罚金',
            collectType: this.detailData['proCosta'] ? this.detailData['proCosta'].split(',')[1] : '00',
            costnum: this.detailData['proCosta'] ? this.detailData['proCosta'].split(',')[0] : '0',
            numflag: true
          },
          {
            costName: '提前还款手续费',
            collectTime: '提前还款时收取',
            nameDefinition: '违反合同约定的处罚金',
            collectType: this.detailData['proCostb'] ? this.detailData['proCostb'].split(',')[1] : '01',
            costnum: this.detailData['proCostb'] ? this.detailData['proCostb'].split(',')[0] : '0',
            numflag: true
          },
          {
            costName: '宽限期手续费',
            collectTime: '展期时收取',
            nameDefinition: '宽限期（展期）时向融资方收取的费用',
            collectType: this.detailData['proCostd'] ? this.detailData['proCostd'].split(',')[1] : '00',
            costnum: this.detailData['proCostd'] ? this.detailData['proCostd'].split(',')[0] : '0',
            numflag: true
          },
          {
            costName: '催收费',
            collectTime: '催收时收取',
            nameDefinition: '产生逾期后催款费用（委托催收、司法诉讼）',
            collectType: this.detailData['proCoste'] ? this.detailData['proCoste'].split(',')[1] : '00',
            costnum: this.detailData['proCoste'] ? this.detailData['proCoste'].split(',')[0] : '0',
            numflag: true
          },
          {
            costName: '保理费',
            collectTime: '放款时收取',
            nameDefinition: '保理费',
            collectType: '00',
            costnum: '0',
            numflag: false,
            factoringType: this.detailData['proCostf']
          },
          {
            costName: '融资利息',
            collectTime: '放款/还款时收取',
            nameDefinition: '融资利息',
            collectType: '00',
            costnum: '0',
            numflag: false,
            preCharge: this.detailData['preCharge']
          }
        ]
        this.appTcapiOld = this.detailData.appTcapi
        this.formatTcapi = this.detailData.appTcapi
        if (val && val['feeInfos']) {
          const otherFeeInfos = []
          const finaFeeInfos = []
          val['feeInfos'].forEach(v => {
            if (v.costOwner === '01')finaFeeInfos.push(v)
            else otherFeeInfos.push(v)
          })
          this.detailData['otherFeeInfos'] = otherFeeInfos
          this.detailData['finaFeeInfos'] = finaFeeInfos
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {

    },
    showHide() {
      this.showBillInfo = !this.showBillInfo
    },
    // 校验费用-平台费用输入框
    checkCostByPlatform(val, unit) {
      if (val === '' || val === undefined || val === null) {
        this.$message({
          type: 'error',
          message: '请输入收取方式'
        })
        this.validFlag = false
      } else if (isNaN(Number(val))) {
        this.$message({
          type: 'error',
          message: '请输入正确的收取方式'
        })
        this.validFlag = false
      } else if (
        val.charAt('0') === '0' &&
      val.charAt('1') !== '.' &&
      val.length > 1
      ) {
        this.$message({
          type: 'error',
          message: '请输入正确的收取方式'
        })
        this.validFlag = false
      } else if (unit === '01') {
      // 单位：笔
        if (!/^(\d+)(\.\d{1,2})?$/.test(val)) {
          this.$message({
            type: 'error',
            message: '请保留1-2位小数'
          })
          this.validFlag = false
        } else if (val.length - 1 > 18) {
          this.$message({
            type: 'error',
            message: '金额不得超过18位'
          })
          this.validFlag = false
        } else {
          this.validFlag = true
        }
      } else {
      // 单位：比例
        if (!/^(\d+)(\.\d{1,5})?$/.test(val)) {
          this.$message({
            type: 'error',
            message: '请保留1-5位小数'
          })
          this.validFlag = false
        } else if (val > 100) {
          this.$message({
            type: 'error',
            message: '比例不能大于100'
          })
          this.validFlag = false
        } else if (val.length - 1 > 8) {
          this.$message({
            type: 'error',
            message: '比例不得超过8位'
          })
          this.validFlag = false
        } else {
          this.validFlag = true
        }
      }
    },
    // 改变单据审核状态，计算融资申请金额
    handleRadioChange(flag) {
      let sum = 0
      this.detailData.billList.list.forEach((item, index) => {
        if (item.finCheckFlag === '00') {
          sum += item.billAmt
        }
      })
      this.detailData.appTcapi = (sum * this.detailData.creditRatio) / 100
      this.formatTcapi = this.detailData.appTcapi
    },
    // 检查每个输入框
    checkAmtRule() {
      let flag1 = false
      // 费用-资金方
      for (var i = 0; i < this.costCapitalSideList.length; i++) {
        const oneitem = this.costCapitalSideList[i]
        if (
          isNaN(Number(oneitem.costnum)) ||
        oneitem.costnum === '' ||
        oneitem.costnum === undefined ||
        oneitem.costnum === null ||
        (oneitem.costnum.charAt('0') === '0' &&
          oneitem.costnum.charAt('1') !== '.' &&
          oneitem.costnum.length > 1)
        ) {
          flag1 = false
          break
        } else if (oneitem.collectType === '01') {
        // 单位：笔
          if (
            !/^(\d+)(\.\d{1,2})?$/.test(oneitem.costnum) ||
          oneitem.costnum.length - 1 > 18
          ) {
            flag1 = false
            break
          } else {
            flag1 = true
            continue
          }
        } else if (oneitem.collectType === '00') {
        // 单位：比例
          if (
            !/^(\d+)(\.\d{1,5})?$/.test(oneitem.costnum) ||
          oneitem.costnum.length - 1 > 8 ||
          oneitem.costnum > 100
          ) {
            flag1 = false
            break
          } else {
            flag1 = true
            continue
          }
        } else {
          flag1 = true
        }
      }

      return flag1
    },
    handleEnclosureDetail() {}
  }
}
</script>
<style scoped lang="scss">
  .finance-information-box{
    .title {
      font-weight: 400;
      padding: 13px 18px;
      margin: 0 -18px;
      font-size: 16px;
      line-height: 22px;
      border-bottom: 1px solid #EEF1F4;

      .tips-txt {
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        width: max-content;
      }
    }

    .basic-information {
      /*padding-top: 20px;*/

      li {
        float: left;
        width: 40%;
        font-size: 14px;
        color: #606266;
        padding-bottom: 10px;
        margin-right: 10px;
        line-height: 30px;

        .li-label {
          text-align: right;
          min-width: 112px;
          font-weight: 600;
        }

        .br-input {
          width: 65%;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .finance-information-box{
      .basic-information{
        li {
          width: 46%;
        }
      }
    }
  }
.down-file {
  cursor: pointer;
  color: #409eff;
}

.parameTitle {
  height: 20px;
  line-height: 20px;
  margin: 15px 0;
  .leftLine {
    width: 3px;
    height: 20px;
    display: inline-block;
    background: #4da1ff;
  }
  .rightText {
    font-size: 14px;
    color: #333;
    padding-left: 12px;
  }
  .more{
    font-size: 14px;
    padding-left: 12px;
    cursor: pointer;
    color: #409eff;
    .br-iconfont{
      -webkit-transition: font-size 0.25s linear, width 0.25s linear;
      -moz-transition: font-size 0.25s linear, width 0.25s linear;
      transition: font-size 0.25s linear, width 0.25s linear;
      font-size: 14px;
    }
    &:hover{
      .br-iconfont{
        font-size: 16px;
      }
    }
  }
  &.sub-title {
    font-size: 12px;
    color: #666666;
    .leftLine {
      background: orange;
      height: 15px;
      vertical-align: middle;
    }
    .rightText {
      vertical-align: middle;
    }
  }
}

.sub-module {
  padding-left: 30px;
}

.grid-content {
  font-size: 13px;
  display: flex;
  align-items: center;
  & > label {
    white-space: nowrap;
    min-width: 125px;
    text-align: right;
  }
}

.br-input-full {
  flex: 1;
}

.br-input-small {
  width: 58px;
  /deep/ input.el-input__inner {
    text-align: center;
    padding: 0;
  }
}

.content-right {
  display: flex;
  justify-content: flex-end;
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

.br-select.br-select-medium {
  width: 80px !important;
  margin: 0 8px;
}

.br-table {
  /deep/ .el-input__icon {
    line-height: 0;
  }
  .br-select {
    width: 160px;
  }
}
</style>

<template>
  <div>
    <!-- 保理融资详情 -->
    <el-tabs v-model="tabVal">
      <el-tab-pane name="1" label="融资申请信息">
        <financeInformation v-if="detailData.financeInfo" :detail-data="detailData" :tab-val="tabVal" />
      </el-tab-pane>
      <el-tab-pane
        v-if="$route.query.currentNodeId && $route.query.activiteId"
        label="流程监控"
        name="2"
      >
        <processMonitor />
      </el-tab-pane>
      <el-tab-pane label="审核历史" name="3">
        <auditHistory />
      </el-tab-pane>
      <el-tab-pane
        v-if="detailData&&$route.query.type=='2' && $route.query.currentNodeId && $route.query.activiteId && (detailData.financeInfo && detailData.financeInfo.appStat === '05'||detailData.financeInfo &&detailData.financeInfo.appStat === '06')"
        label="审核意见"
        name="4"
      >
        <auditSuggestion />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import { getSelectFinanceDetails } from '@/api/tradeFinancing.js'
import FinanceInformation from './financeInformation.vue'
import AuditSuggestion from './auditSuggestion.vue'
import AuditHistory from './auditHistory'
import ProcessMonitor from './processMonitor'
export default {
  components: { FinanceInformation, AuditSuggestion, AuditHistory, ProcessMonitor },
  data() {
    return {
      detailData: {},
      detailInfo: {},
      tabVal: '1',
      detailObj: {},
      detailsItemArr: [
        { label: '应收/应付账款内部编码', value: 'cmpName' },
        { label: '企业名称(卖方)', value: 'settleBankName' },
        { label: '企业名称(买方)', money: 'amount' },
        { label: '应收/应付账款到期日', value: 'operPlat', child: [{ id: '01', val: '平台' }, { id: '02', val: '银行' }] },
        { label: '应收/应付账款金额', value: 'payWay', child: [{ id: '011', val: '现金' }, { id: '012', val: '网银转账' }, { id: '021', val: '银行接口' }, { id: '022', val: '柜台' }] },
        { label: '已收金额', value: 'bankBusiNo' },
        { label: '数据来源', value: 'openBankName' },
        { label: '确认状态', value: 'openAccName' },
        { label: '转让状态', value: 'openAccNo' },
        { label: '收款/付款状态', value: 'openBankNet' },
        { label: '状态', value: 'state', child: [{ id: '01', val: '有效' }, { id: '02', val: '待平台审核' }, { id: '10', val: '待银行审核' }, { id: '11', val: '银行拒绝' }, { id: '20', val: '平台拒绝' }] }
      ],
      listData: [],
      itemData: [
        { label: '申请编号', prop: 'openBankName' },
        { label: '当前节点', prop: 'openBankName2' },
        { label: '审核人', prop: 'openBankName3' },
        { label: '审核意见', prop: 'openBankName4' },
        { label: '审核说明', prop: 'openBankName5' },
        { label: '审核时间', prop: 'openBankNam7' }
      ]
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      getSelectFinanceDetails(this.$route.query.financeId, res => {
        // this.detailInfo = res.data
        this.detailData = res.data
      })
    }
  }
}
</script>

<style>

</style>
